import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ApiValidation = ({ apiUrl, validateKey, expectedValue, children }) => {
    const [isApiValid, setIsApiValid] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const validateApi = async () => {
            try {
                const response = await axios.get(apiUrl);
                const { data } = response;

                // Check if the response contains the expected key-value pair
                if (data == 1) {
                    setIsApiValid(true);
                } else {
                    setIsApiValid(false);
                    setErrorMessage('Invalid API response.');
                }
            } catch (error) {
                console.error('API validation error:', error);
                setIsApiValid(false);
                setErrorMessage('Failed to validate API.');
            }
        };

        validateApi();
    }, [apiUrl, validateKey, expectedValue]);

    if (isApiValid === null) {
        return <div></div>; // Optionally, you can show a loading spinner or message
    }

    if (isApiValid) {
        return <>{children}</>; // Render the main app if API validation passes
    } else {
        return <div>{errorMessage}</div>; // Display an error message if validation fails
    }
};

export default ApiValidation;
