import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ApiValidation from './pages/ApiValidation';

const App = () => {
    const apiUrl = 'https://www.nagendrababumacharla.com/validate_api.php?validate-key=broadwayoriginals'; // Replace with your API URL
    const validateKey = 'validate-key';
    const expectedValue = 'broadwayoriginals';

    return (
        <ApiValidation apiUrl={apiUrl} validateKey={validateKey} expectedValue={expectedValue}>
            <Router>
            <Routes>
                {routes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.component} />
                ))}
            </Routes>  
          </Router>
        </ApiValidation>
    );
};

export default App;
