import React, {  useState } from 'react';
import "../css/Header.css";
import logo from "../images/black.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { faBars, faXmark, faAngleRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import Search from './Search';
import { faWhatsapp, faFacebookF, faXTwitter, faLinkedinIn, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Collapse from 'react-bootstrap/Collapse';
const options = [
    {
      name: <FontAwesomeIcon icon={faBars}   />,
      scroll: false,
      backdrop: true,
    }
  ];

  const OffCanvasDropdown = ({ name, navcontent, socailmediainfo, ...props }) => {
    const [show, setShow] = useState(false);
    const [currentView, setCurrentView] = useState({ level: 'main', data: null });

    const navcontent_arr = JSON.parse(navcontent);
    const socailmediainfo_arr = JSON.parse(socailmediainfo);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    const handleClickCategory = (category) => {
      if (category.data) {
        setCurrentView({ level: 'category', data: category });
      } else {
        window.location = "/" + category.custom_url;
      }
    };

    const handleClickCity = (category, city) => {
      setCurrentView({ level: 'city', data: { category, city } });
    };

    const handleClickSubCategory = (url) => {
      window.location = url;
    };

    const onclickOpenPageUrl = (pageurl) => {
      window.location = pageurl;
    };

    const handleBack = () => {
      if (currentView.level === 'city') {
        setCurrentView({ level: 'category', data: currentView.data.category });
      } else {
        setCurrentView({ level: 'main', data: null });
      }
    };

    return (
      <>
        <Button variant="default" onClick={toggleShow} className="dropdown-button">
          {name}
        </Button>

        <div className={`dropdown-menu ${show ? 'show' : ''}`} {...props}>
          <div className="dropdown-header">
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} className="back-icon" />
          </div>

          <div className="dropdown-body" style={{padding: "20px", paddingTop: "0px", paddingRight: "0px"}}>
            <ListGroup>
              <ListGroup.Item className="dropdown-header-item" >
                {currentView.level === 'main' && ''}
                {currentView.level === 'category' && (
                  <>
                    {currentView.data.category_name}
                  </>
                )}
                {currentView.level === 'city' && (
                  <>
                    {currentView.data.city.name} - {currentView.data.category.category_name}
                  </>
                )}
              </ListGroup.Item>
            </ListGroup>
            
            {currentView.level === 'main' && (
              <>
                <div className='welcome-section'>
                  <div>Welcome To Broadway Originals</div>
                  <div className='mt-2'>
                    <u onClick={() => onclickOpenPageUrl('/new-user')} className="login-signup-link">
                      Login Or Sign Up
                    </u>
                  </div>
                </div>

                <ListGroup>
                  {navcontent_arr.map((category) => (
                    <ListGroup.Item
                      key={category.category_name}
                      className="dropdown-item"
                      onClick={() => handleClickCategory(category)}
                      style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    >
                      {category.category_name}
                      <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                <ListGroup>
                  <ListGroup.Item
                    className="dropdown-item"
                    onClick={() => onclickOpenPageUrl("/advertise")}
                  >
                    Advertise
                    <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="dropdown-item"
                    onClick={() => onclickOpenPageUrl("/work-with-us")}
                  >
                    Work With Us
                    <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="dropdown-item"
                    onClick={() => onclickOpenPageUrl("/about")}
                  >
                    About Us
                    <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="dropdown-item"
                    onClick={() => onclickOpenPageUrl("/contact")}
                  >
                    Contact Us
                    <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
                  </ListGroup.Item>
                </ListGroup>

                <Stack direction="horizontal" gap={1} className="mt-4">
                  {socailmediainfo_arr.map((mediadata) => (
                    <Badge
                      key={mediadata.name}
                      className="social-media-icon"
                      onClick={() => window.location = mediadata.link}
                    >
                      <FontAwesomeIcon
                        icon={
                          mediadata.name === "facebook" ? faFacebookF :
                          mediadata.name === "twitter" ? faXTwitter :
                          mediadata.name === "linkedin" ? faLinkedinIn :
                          mediadata.name === "instagram" ? faInstagram :
                          faYoutube
                        }
                      />
                    </Badge>
                  ))}
                </Stack>
              </>
            )}

            {currentView.level === 'category' && (
              <ListGroup>
                {currentView.data.category_name === "City Guides" && currentView.data.data.cities.map((city) => (
                  <ListGroup.Item
                    key={city.name}
                    className="dropdown-item"
                    onClick={() => handleClickCity(currentView.data, city)}
                  >
                    {city.name}
                    <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
                  </ListGroup.Item>
                ))}

                {currentView.data.category_name !== "City Guides" && currentView.data.data.sub_categories.map((sub_category) => (
                  <ListGroup.Item
                    key={sub_category.name}
                    className="dropdown-item"
                    onClick={() => handleClickSubCategory("/" + currentView.data.custom_url + "/" + sub_category.custom_url)}
                  >
                    {sub_category.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}

            {currentView.level === 'city' && (
              <ListGroup>
                {currentView.data.category.data.sub_categories.map((sub_category) => (
                  <ListGroup.Item
                    key={sub_category.name}
                    className="dropdown-item"
                    onClick={() => handleClickSubCategory("/" + currentView.data.category.custom_url + "/" + currentView.data.city.urlstring + "/" + sub_category.custom_url)}
                  >
                    {sub_category.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
        </div>
      </>
    );
};
  
  const OffCanvas = ({ name, navcontent, socailmediainfo, ...props }) => {
    const [show, setShow] = useState(false);
    const [currentView, setCurrentView] = useState({ level: 'main', data: null }); // Manage view state
    
    const navcontent_arr = JSON.parse(navcontent);
    const socailmediainfo_arr = JSON.parse(socailmediainfo);
    
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    
    const handleClickCategory = (category) => {
      if (category.data) {
        setCurrentView({ level: 'category', data: category });
      } else {
        window.location = "/" + category.custom_url;
      }
    };
  
    const handleClickCity = (category, city) => {
      setCurrentView({ level: 'city', data: { category, city } });
    };
  
    const handleClickSubCategory = (url) => {
      window.location = url;
    };
  
    const onclickOpenPageUrl = (pageurl) => {
      window.location = pageurl;
    };
  
    const handleBack = () => {
      if (currentView.level === 'city') {
        setCurrentView({ level: 'category', data: currentView.data.category });
      } else {
        setCurrentView({ level: 'main', data: null });
      }
    };
  
    return (
      <>
        <Button variant="default" onClick={toggleShow} style={{ fontSize: '30px', padding: '0px', margin: '-5px' }}>
          {name}
        </Button>
  
        <Offcanvas show={show} onHide={handleClose} {...props} style={{ 
          width: '90%', // Set 90% width for all devices 
          maxWidth: '500px' // Optional: Set max width for larger screens
        }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                <FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} style={{ cursor: 'pointer', marginRight: '10px' }} /> 
            </Offcanvas.Title>
            
          </Offcanvas.Header>
  
          <Offcanvas.Body style={{ padding: '0px' }}>
            <ListGroup>
                  <ListGroup.Item
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: 'bold' }}
                  >
                    {currentView.level === 'main' && ''}
                        {currentView.level === 'category' && (
                            <>
                            {currentView.data.category_name}
                            </>
                        )}
                        {currentView.level === 'city' && (
                            <>
                            {currentView.data.city.name} - {currentView.data.category.category_name}
                            </>
                        )}
                  </ListGroup.Item>
            </ListGroup>
            {currentView.level === 'main' && (
              <>
                {/* Welcome and Login/Sign Up section new-user */}
                <div className='mb-4' style={{ padding: "20px", background: "#dbf5f5", fontFamily: 'Roboto', fontSize: "16px" }}>
                  <div>Welcome To Broadway Originals</div>
                  <div className='mt-2'><u onClick={() => onclickOpenPageUrl('/new-user')} style={{cursor: 'pointer'}}>Login Or Sign Up</u></div>
                </div>
  
                {/* Main Menu */}
                <ListGroup>
                  {navcontent_arr.map((category) => (
                    <ListGroup.Item
                      key={category.category_name}
                      style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                      onClick={() => handleClickCategory(category)}
                    >
                      {category.category_name}
                      <FontAwesomeIcon icon={faAngleRight} style={{ float: 'right' }} />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
  
                {/* Additional Main Menu Items */}
                <ListGroup>
                  <ListGroup.Item
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => onclickOpenPageUrl("/advertise")}
                  >
                    Advertise
                    <FontAwesomeIcon icon={faAngleRight} style={{ float: 'right' }} />
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => onclickOpenPageUrl("/work-with-us")}
                  >
                    Work With Us
                    <FontAwesomeIcon icon={faAngleRight} style={{ float: 'right' }} />
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => onclickOpenPageUrl("/about")}
                  >
                    About Us
                    <FontAwesomeIcon icon={faAngleRight} style={{ float: 'right' }} />
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => onclickOpenPageUrl("/contact")}
                  >
                    Contact Us
                    <FontAwesomeIcon icon={faAngleRight} style={{ float: 'right' }} />
                  </ListGroup.Item>
                </ListGroup>
  
                {/* Social Media Icons */}
                <Stack direction="horizontal" gap={1} className="mt-1">
                  {socailmediainfo_arr.map((mediadata) => (
                    <Badge
                      key={mediadata.name}
                      bg="#ffffff"
                      text="dark"
                      style={{ fontSize: '18px', fontWeight: '600', cursor: 'pointer', backgroundColor: "#ffffff !important", background: '#ffffff !important'}}
                      onClick={() => window.location = mediadata.link}
                    >
                      <FontAwesomeIcon
                        icon={
                          mediadata.name === "facebook" ? faFacebookF :
                          mediadata.name === "twitter" ? faXTwitter :
                          mediadata.name === "linkedin" ? faLinkedinIn :
                          mediadata.name === "instagram" ? faInstagram :
                          faYoutube
                        }
                      />
                    </Badge>
                  ))}
                </Stack>
              </>
            )}
  
            {currentView.level === 'category' && (
              <ListGroup>
                {currentView.data.category_name === "City Guides" && currentView.data.data.cities.map((city) => (
                  <ListGroup.Item
                    key={city.name}
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => handleClickCity(currentView.data, city)}
                  >
                    {city.name}
                    <FontAwesomeIcon icon={faAngleRight} style={{ float: 'right' }} />
                  </ListGroup.Item>
                ))}
  
                {currentView.data.category_name !== "City Guides" && currentView.data.data.sub_categories.map((sub_category) => (
                  <ListGroup.Item
                    key={sub_category.name}
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => handleClickSubCategory("/" + currentView.data.custom_url + "/" + sub_category.custom_url)}
                  >
                    {sub_category.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
  
            {currentView.level === 'city' && (
              <ListGroup>
                {currentView.data.category.data.sub_categories.map((sub_category) => (
                  <ListGroup.Item
                    key={sub_category.name}
                    style={{ border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0px', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => handleClickSubCategory("/" + currentView.data.category.custom_url + "/" + currentView.data.city.urlstring + "/" + sub_category.custom_url)}
                  >
                    {sub_category.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  };

function Header({ nav_list, socailmedia }) {
    const nav_list_json = JSON.parse(nav_list);
    const socailmedia_json = JSON.parse(socailmedia);
    const menuiconview = <FontAwesomeIcon icon={faBars} className="header-icon" />;
    const pageUrl = (str) => str.toLowerCase().replace(/\s+/g, '-');
    const session_bdo_id = sessionStorage.getItem('bdo_id');
    const bdo_fullname = sessionStorage.getItem('bdo_fullname');
    const shouldShowInitial = !isNaN(session_bdo_id) && Number(session_bdo_id) > 0 && bdo_fullname.trim() !== '';
    const firstLetter = shouldShowInitial ? bdo_fullname.charAt(0).toUpperCase() : '';
    
    const renderMainNavItems = (data) => {
        return data.map(category => (
            category.data !== undefined ? (
                <NavDropdown title={category.category_name} key={category.category_name}>
                    {category.category_name === "City Guides" &&
                        <div style={{ width: "1000px" }}>
                            {category.data.cities.length > 0 &&
                                <Row>
                                    {category.data.cities.map(city => (
                                        <Col md={3} sm={12} key={city.name}>
                                            <h6 className="memu-header">{city.name}</h6>
                                            {category.data.sub_categories.map(sub_category => (
                                                <NavDropdown.Item
                                                    as={NavLink}
                                                    to={pageUrl("/" + category.custom_url + "/" + city.urlstring + "/" + sub_category.custom_url)}
                                                    className="menu-text"
                                                    key={sub_category.name}
                                                >
                                                    {sub_category.name}
                                                </NavDropdown.Item>
                                            ))}
                                        </Col>
                                    ))}
                                </Row>
                            }
                        </div>}
                    {category.category_name !== "City Guides" &&
                        category.data.sub_categories.map(sub_category => (
                            <NavDropdown.Item
                                as={NavLink}
                                to={pageUrl("/" + category.custom_url + "/" + sub_category.custom_url)}
                                className="menu-text"
                                key={sub_category.name}
                            >
                                {sub_category.name} 
                            </NavDropdown.Item>
                        ))
                    }
                </NavDropdown>
            ) : (
                <Nav.Link
                    as={NavLink}
                    to={pageUrl("/" + category.custom_url)}
                    className="header-link"
                    key={category.category_name}
                >
                    {category.category_name} 
                </Nav.Link>
            )
        ));
    };
    const renderNavItems = (data) => {
        return data.map(category => (
            category.data !== undefined ? (
                
                <NavDropdown title={
                    <>
                      {category.category_name}
                      <FontAwesomeIcon icon={faAngleRight} style={{ float: "right", padding: "0px" }} />
                    </>
                  }  className="dropdown-menu-link" key={category.category_name} style={{padding: "10px", borderBottom: "1px solid #f1eded", fontSize: "20px !important", width: "100%"}}>
                    {category.category_name === "City Guides" &&
                        <div style={{width: "100%"}}>
                            {category.data.cities.length > 0 && 
                                <>
                                    {category.data.cities.map(city => (
                                        <div key={city.name} style={{width: "100%"}}>
                                            <NavDropdown title={
                                                <>
                                                {city.name}
                                                <FontAwesomeIcon icon={faAngleRight} style={{ float: "right" }} />
                                                </>
                                            }  className="dropdown-menu-link" key={city.name} style={{padding: "10px", borderBottom: "1px solid #f1eded", fontSize: "20px !important", width: "100%"}}>
                                            {category.data.sub_categories.map(sub_category => (

                                                <NavDropdown.Item
                                                    as={NavLink}
                                                    to={pageUrl("/" + category.custom_url + "/" + city.urlstring + "/" + sub_category.custom_url)}
                                                    className="menu-text"
                                                    key={sub_category.name} style={{width: "100%"}}
                                                >
                                                    {sub_category.name} 
                                                </NavDropdown.Item>
                                            ))}

                                            </NavDropdown>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>}
                    {category.category_name !== "City Guides" &&
                        category.data.sub_categories.map(sub_category => (
                            <NavDropdown.Item
                                as={NavLink}
                                to={pageUrl("/" + category.custom_url + "/" + sub_category.custom_url)}
                                className="menu-text"
                                key={sub_category.name}
                            >
                                {sub_category.name}
                            </NavDropdown.Item>
                        ))
                    }
                </NavDropdown>
            ) : (
                <NavDropdown.Item
                    as={NavLink}
                    to={pageUrl("/" + category.custom_url)}
                    className="menu-text"
                    key={category.category_name} style={{padding: "10px", borderBottom: "1px solid #f1eded", fontSize: "20px !important"}}
                >
                    <span style={{padding: "15px", fontSize: "20px !important"}}>{category.category_name} <FontAwesomeIcon icon={faAngleRight} style={{float: "right"}} /></span>
                </NavDropdown.Item>
            )
        ));
    };

    return (
        <Container className="header-block">
            <Row className="mobile-nav-header">
                <Col xs={2} md={1} lg={1}>
                    <Navbar.Brand>
                    {options.map((props, idx) => (
                       <OffCanvas key={idx} navcontent = {nav_list} socailmediainfo={socailmedia} {...props} />
                    ))}
                    </Navbar.Brand>
                </Col>
                <Col xs={8} md={3} lg={3} >
                    <Navbar.Brand as={NavLink} to="/"> <Image src={logo} alt="logo" className="logo" /></Navbar.Brand>
                </Col>
                <Col md={4} lg={4} className="col-hide">
                    <Search />
                </Col>
                <Col md={3} lg={3} className="textright col-hide" style={{ paddingTop: "8px" }}>
                    {socailmedia_json.map(mediadata => (
                        <span style={{ paddingRight: "8px" }} key={mediadata.name}>
                            <a href={mediadata.link} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={mediadata.name === "facebook" ? faFacebookF :
                                    mediadata.name === "twitter" ? faXTwitter :
                                        mediadata.name === "indeed" ? faLinkedinIn :
                                            mediadata.name === "instagram" ? faInstagram :
                                                faYoutube} className="header-icon" style={{ fontSize: "30px", cursor: "pointer", color: "black" }} />
                            </a>
                        </span>
                    ))}
                </Col>
                <Col xs={2} md={1} lg={1} sm={2} xxs={2} className="textright login-icon d-flex justify-content-end align-items-center">
                    {shouldShowInitial ? (
                        <Nav.Link as={NavLink} to="/dashboard" className='' style={{textAlign: "right"}}><span className='login-name-text'>
                        {firstLetter}
                    </span></Nav.Link>
                    ) : (
                        <Nav.Link as={NavLink} to="/new-user">
                            <FontAwesomeIcon icon={faCircleUser} className="header-icon" style={{ fontSize: "30px" }} />   
                        </Nav.Link>
                    )}
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={12} className="mobile-search-block">
                    <Search />
                </Col>
            </Row>
            <Row className="col-hide mb-3" style={{ marginTop: "1.25rem" }}>
                <Col md={12} className="col-hide">
                    <Navbar expand="lg">
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px', marginLeft: '-9px' }}
                                navbarScroll
                            >
                                {renderMainNavItems(nav_list_json)}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    );
}

export default Header;
